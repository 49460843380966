<template>
  <div class="price-list-detail" v-if="priceList">
    <PriceListDetailHeader
      :priceList="priceList"
      :isPriceListProposal="isPriceListProposal"
      :totItems="productPrices.page.totItems"
      @updatePriceList="updatePriceList"
      @validateProposalRequest="validateProposalRequest"
    />
    <v-list
      v-if="
        productPrices.productPrices && productPrices.productPrices.length > 0
      "
      :key="refreshKey"
      flat
      class="product-price-list"
    >
      <template v-for="productPrice in productPrices.productPrices">
        <PriceListDetailRow
          :key="productPrice.refreshItemKey || productPrice.priceId"
          :productPrice="productPrice"
          :priceListProposalId="priceList.priceListProposalId"
          :proposalStatus="
            priceList.publicationState
              ? priceList.publicationState.publicationStateId
              : undefined
          "
          @deleteProductPrice="deleteProductPrice"
          @updateProductPrice="updateProductPrice"
        />
      </template>
    </v-list>
    <v-pagination
      v-if="!loading && pager && pager.totPages > 1"
      :value="pageFilter"
      :length="pager.totPages"
      :totalVisible="$vuetify.breakpoint.xs ? 6 : 7"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
    ></v-pagination>
  </div>
</template>
<style lang="scss">
.price-list-detail {
  .price-list-detail-table {
    margin-top: 20px;
  }
  .product-price-list {
    margin-top: 20px;
  }
  .product-price-item {
    border-bottom: 1px solid (--v-grey-lighten3);
  }
}
</style>
<script>
import PriceListDetailHeader from "@/components/priceList/PriceListDetailHeader.vue";
import PriceListDetailRow from "@/components/priceList/PriceListDetailRow.vue";

import priceListService from "~/service/priceListService";
import priceListProposalService from "~/service/priceListProposalService";

// import { mapGetters } from "vuex";

export default {
  name: "PriceListDetail",
  components: {
    PriceListDetailRow,
    PriceListDetailHeader
  },
  props: {
    isPriceListProposal: { type: Boolean, default: false }
  },
  data() {
    return {
      loaded: false,
      priceList: null,
      productPrices: null,
      refreshKey: 0,
      pageFilter: 1,
      pager: {},
      virtualPageSize: 15,
      loading: true
    };
  },
  methods: {
    async fetchPriceListHeaders() {
      let result;
      if (this.isPriceListProposal) {
        result = await priceListProposalService.getDetail(
          this.$route.params.priceListProposalId
        );
      } else {
        result = await priceListService.getDetail(
          this.$route.params.priceListId
        );
      }
      if (result.response?.status == 0 && result.data) {
        this.priceList = result.data;
      } else if (result.response) {
        this.errorMessage = result.response;
      }
    },
    async fetchPriceListProposal(doNotScrollTop) {
      let _this = this;
      try {
        this.loading = true;
        const resultProductPrices = await priceListProposalService.getProductPrices(
          this.$route.params.priceListProposalId,
          this.pageFilter,
          this.pageSize
        );
        if (
          resultProductPrices.response?.status == 0 &&
          resultProductPrices.data
        ) {
          this.productPrices = resultProductPrices.data;
          this.pager = resultProductPrices.data.page;
        } else if (resultProductPrices.response) {
          this.errorMessage = resultProductPrices.response;
        }
        this.refreshKey++;
        if (!doNotScrollTop) {
          setTimeout(() => {
            _this.$vuetify.goTo(0);
          }, 100);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchPriceList() {
      this.loading = true;
      try {
        const resultProductPrices = await priceListService.getProductPrices(
          this.$route.params.priceListId,
          this.pageFilter,
          this.pageSize
        );
        if (
          resultProductPrices.response?.status == 0 &&
          resultProductPrices.data
        ) {
          this.productPrices = resultProductPrices.data;
          this.pager = resultProductPrices.data.page;
        } else if (resultProductPrices.response) {
          this.errorMessage = resultProductPrices.response;
        }
        this.refreshKey++;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async validateProposalRequest() {
      if (this.priceList.priceListProposalId) {
        const result = await priceListProposalService.validateProposal(
          this.priceList.priceListProposalId
        );
        if (result.response?.status == 0 && result.data) {
          this.priceList = result.data;
        }
      }
    },
    async deleteProductPrice(params) {
      const deleteResult = await priceListProposalService.deleteProductPrice(
        params.priceId,
        this.priceList.priceListProposalId
      );
      if (deleteResult.response?.status == 0 && deleteResult.data) {
        const productPricesListIndex = this.productPrices.productPrices.findIndex(
          productPrice =>
            productPrice.priceId == deleteResult.data.productPrices[0].priceId
        );
        this.productPrices.productPrices.splice(productPricesListIndex, 1);
        this.refreshKey++;
      }
    },
    async updateProductPrice(params) {
      const updateResult = await priceListProposalService.updateProductPrice(
        params.price,
        params.priceId,
        this.priceList.priceListProposalId
      );
      if (updateResult.response?.status == 0 && updateResult.data) {
        const productPricesListIndex = this.productPrices.productPrices.findIndex(
          productPrice =>
            productPrice.priceId == updateResult.data.productPrices[0].priceId
        );
        this.productPrices.productPrices[productPricesListIndex] =
          updateResult.data.productPrices[0];
        // this.productPrices.productPrices[
        //   productPricesListIndex
        // ].refreshItemKey = Math.random();
        this.refreshKey++;
      }
    },
    async reload(doNotScrollTop = false) {
      this.loaded = false;
      if (this.isPriceListProposal) {
        await this.fetchPriceListProposal(doNotScrollTop);
      } else {
        await this.fetchPriceList(doNotScrollTop);
      }

      this.loaded = true;
    },
    handlePageFilter(newPage) {
      const query = {
        ...this.$route.query,
        page: newPage
      };
      this.pageFilter = newPage;
      this.$router.push({
        query
      });
      this.reload();
    },
    updatePriceList(newPriceList) {
      console.log(newPriceList);
      this.priceList = newPriceList;
    }

    // async deleteOrder(orderId) {
    //   await OrderService.deleteOrder(orderId);
    //   this.reload();
    // },
    // duplicatePriceList(priceListId) {
    //   OrderService.editOrder(orderId);
    // },
  },
  async mounted() {
    await this.fetchPriceListHeaders();
    this.pageFilter = parseInt(this.$route.query.page || 1);
    this.reload();
  }
};
</script>
