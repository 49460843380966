<template>
  <v-card flat class="price-list-detail-table" color="grey lighten-3">
    <v-row>
      <v-col class="left-block" cols="12" lg="7" md="12">
        <div class="price-list-detail-table-info price-list-name">
          {{ $t("priceList.priceListDetail.header.priceListName") }}
          <strong>{{
            isPriceListProposal ? priceList.priceList.name : priceList.name
          }}</strong>
        </div>
        <div
          v-if="priceList.publicationState && isPriceListProposal"
          class="price-list-detail-table-info price-list-status"
          :class="priceList.publicationState"
        >
          {{ $t("priceList.priceListDetail.header.status") }}
          <PriceListProposalStatus
            :publicationState="priceList.publicationState"
          />
        </div>
        <div v-else>
          {{ $t("priceList.priceListDetail.header.status") }}

          <PriceListProposalStatus
            :status="
              $dayjs().isBefore($dayjs(priceList.toDate).add(1, 'day')) ? 1 : 0
            "
          />
        </div>
        <div v-if="!isPriceListProposal">
          {{ $t("priceList.priceListDetail.header.id") }}

          {{ priceList.priceListId }}
        </div>
        <v-card-actions class="px-0">
          <v-btn
            v-if="!isPriceListProposal"
            color="primary"
            outlined
            depressed
            @click="editPriceList()"
          >
            {{ $t("priceList.priceListDetail.edit") }}
            <v-icon small class="pl-1">$edit</v-icon>
          </v-btn>
          <v-btn
            v-if="
              !isPriceListProposal &&
                $dayjs().isBefore($dayjs(priceList.toDate))
            "
            color="primary"
            outlined
            depressed
            @click="closePriceList()"
          >
            {{ $t("priceList.priceListDetail.close") }}
            <v-icon small class="pl-1">$delete</v-icon>
          </v-btn>
          <v-btn
            v-if="
              isPriceListProposal &&
                priceList.publicationState.publicationStateId == -1
            "
            color="primary"
            outlined
            depressed
            @click="deletePriceList()"
          >
            {{ $t("priceList.priceListDetail.delete") }}
            <v-icon small class="pl-1">$delete</v-icon>
          </v-btn>
          <v-btn
            v-if="
              isPriceListProposal &&
                priceList.publicationState.publicationStateId == -1
            "
            color="primary"
            depressed
            @click="validateProposalRequest()"
          >
            {{ $t("priceList.priceListDetail.approvalRequest") }}
            <v-icon small class="pl-1">$starfull</v-icon>
          </v-btn>
          <v-btn
            v-if="
              isPriceListProposal &&
                priceList.publicationState.publicationStateId == -1
            "
            color="primary"
            depressed
            @click="editPriceListProposal()"
          >
            {{ $t("priceList.priceListDetail.edit") }}
            <v-icon small class="pl-1">$edit</v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
      <v-col class="right-block" cols="12" lg="5" md="12">
        <div class="price-list-detail-table-info price-list-begin-date">
          {{ $t("priceList.priceListDetail.header.beginDate") }}
          <strong>
            {{
              $dayjs(
                priceList.priceList && priceList.priceList.fromDate
                  ? priceList.priceList.fromDate
                  : priceList.fromDate
              ).format("DD MMMM YYYY")
            }}
          </strong>
        </div>
        <div class="price-list-detail-table-info price-list-end-date">
          {{ $t("priceList.priceListDetail.header.endDate") }}
          <strong>
            {{
              $dayjs(
                priceList.priceList && priceList.priceList.toDate
                  ? priceList.priceList.toDate
                  : priceList.toDate
              ).format("DD MMMM YYYY")
            }}
          </strong>
        </div>
        <div class="price-list-detail-table-info price-list-end-date">
          {{ $t("priceList.priceListDetail.header.warehouse") }}
          <strong>
            {{
              priceList && priceList.warehouse ? priceList.warehouse.name : ""
            }}
          </strong>
        </div>
        <div class="price-list-detail-table-info price-list-end-date">
          {{ $t("priceList.priceListDetail.header.items") }}
          <strong>
            {{ this.totItems }}
          </strong>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.price-list-detail-table {
  padding: 16px;
  @media #{map-get($display-breakpoints, "lg-and-up")} {
    .left-block {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .price-list-detail-table-info {
    font-size: 1rem;
    line-height: 2rem;
  }
  .price-list-name {
    strong {
      color: var(--v-primary-base);
    }
  }
}
</style>
<script>
import PriceListProposalStatus from "@/components/priceList/PriceListProposalStatus.vue";
import ModifyPriceListProposal from "@/components/priceList/ModifyPriceListProposal.vue";
import InsertPriceListProposal from "@/components/priceList/InsertPriceListProposal.vue";
import ClosePriceList from "@/components/priceList/ClosePriceList.vue";
import priceListProposalService from "~/service/priceListProposalService";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

export default {
  name: "PriceListDetailHeader",
  mixins: [cartInfo, login],
  components: {
    PriceListProposalStatus
  },
  props: {
    priceList: { type: Object, required: true },
    isPriceListProposal: { type: Boolean, default: false },
    totItems: { type: Number, default: 0 }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    async editPriceList() {
      const newProposal = await this.$dialog.show(InsertPriceListProposal, {
        waitForResult: true,
        replacedPriceListId: this.priceList.priceListId,
        replacedPriceListName: this.priceList.name
      });
      if (newProposal && newProposal === "changeWharehouse") {
        await this.openAddressSelector();
        setTimeout(() => {
          this.editPriceList();
        }, 300);
      } else if (newProposal && newProposal.priceListProposalId) {
        this.$router.push({
          name: "PriceListProposalDetail",
          params: {
            priceListProposalId: newProposal.priceListProposalId
          }
        });
      }
    },
    async closePriceList() {
      const newProposal = await this.$dialog.show(ClosePriceList, {
        waitForResult: true,
        priceList: this.priceList
      });
      /*if (newProposal && newProposal === "changeWharehouse") {
        await this.openAddressSelector();
        setTimeout(() => {
          this.editPriceList();
        }, 300);
      } else */ if (
        newProposal &&
        newProposal.priceListId
      ) {
        this.$router.push({
          name: "PriceListDetail",
          params: {
            priceListId: newProposal.priceListId
          }
        });
      }
    },
    async editPriceListProposal() {
      const modifyProposal = await this.$dialog.show(ModifyPriceListProposal, {
        waitForResult: true,
        priceListProposal: this.priceList
      });
      if (modifyProposal && modifyProposal.priceListProposalId) {
        this.$emit("updatePriceList", modifyProposal);
      }
    },
    async deletePriceList() {
      try {
        const confirmResult = await this.$dialog.confirm({
          text: global.vm.$t("priceList.priceListDetail.deleteConfirm", {
            priceListName: this.priceList.name
          })
        });
        if (confirmResult) {
          let deleteResult = await priceListProposalService.delete(
            this.priceList.priceListProposalId
          );
          if (deleteResult.response?.status == 0) {
            this.$router.push({ name: "PriceListProposalList" });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async validateProposalRequest() {
      const agreedConfirm = await this.$dialog.confirm({
        text: global.vm.$t("priceList.priceListItem.validationConfirmDialog")
      });
      if (agreedConfirm) {
        this.$emit("validateProposalRequest");
      }
    }
  }
};
</script>
