<template>
  <v-form ref="form" v-model="formModel" @submit.prevent="closePriceList">
    <v-card class="add-to-price-list-card">
      <v-card-title>
        Chiudi listino #{{ this.priceList.priceListId }}
      </v-card-title>
      <v-card-subtitle>
        Data Inizio :
        {{ $dayjs(this.priceList.fromDate).format("DD-MM-YYYY") }}<br />
        Data Fine :
        {{ $dayjs(this.priceList.toDate).format("DD-MM-YYYY") }}
      </v-card-subtitle>

      <v-card-text>
        <!-- END DATE MENU INIT -->
        <v-menu
          ref="menu"
          v-model="menutoDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDatePickerModel"
              placeholder="gg-mm-aaaa"
              :label="$t('priceList.insertPriceListProposal.toDate')"
              :rules="dateRules"
              class="mt-1"
              required
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              hide-details="auto"
              append-icon="$calendar"
              @click:append="menutoDate = true"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            class="w-100"
            locale="it-IT"
            ref="picker"
            color="primary"
            v-model="selectedDate"
            first-day-of-week="1"
            type="date"
            no-title
            @input="handleDateChange"
          ></v-date-picker>
        </v-menu>
        <!-- END MENU END -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined depressed @click="abort">
          {{ $t("common.abort") }}
        </v-btn>
        <v-btn type="submit" depressed color="primary">
          {{ $t("priceList.insertPriceListProposal.submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<style lang="scss">
.add-to-price-list-card {
  .v-picker.v-card.v-picker--date {
    width: 100%;
  }
}
</style>
<script>
import priceListService from "~/service/priceListService";
import priceListProposalService from "~/service/priceListProposalService";

import { requiredValue } from "~/validator/validationRules";

export default {
  name: "ClosePriceList",
  props: {
    priceList: { type: Object, required: false }
  },
  data() {
    return {
      errorMessage: {},
      formModel: undefined,
      loaded: false,
      warehouseList: [],
      warehouseListModel: undefined,
      priceListList: undefined,
      priceListIdModel: undefined,
      priceListNameModel: undefined,
      menufromDate: false,
      fromDatePickerModel: undefined,
      menutoDate: false,
      selectedDate: this.$dayjs().isBefore(this.$dayjs(this.priceList.fromDate))
        ? this.$dayjs(this.priceList.fromDate).format("YYYY-MM-DD")
        : this.$dayjs().format("YYYY-MM-DD"),
      //clonePricesModel: false
      dateRules: [requiredValue("Inserire una data")],
      requiredRules: [requiredValue()]
    };
  },
  computed: {
    toDatePickerModel: {
      get: function() {
        return this.selectedDate
          ? this.$dayjs(this.selectedDate).format("DD-MM-YYYY")
          : this.$dayjs().isBefore(this.$dayjs(this.priceList.fromDate))
          ? this.$dayjs(this.priceList.fromDate).format("DD-MM-YYYY")
          : this.$dayjs().format("DD-MM-YYYY");
      },
      set: function(value) {
        this.selectedDate = this.$dayjs(value, "DD-MM-YYYY").format(
          "DD-MM-YYYY"
        );
      }
    }
  },
  methods: {
    async fetchPriceListList() {
      try {
        const result = await priceListService.getListToClone();
        if (result.response?.status == 0 && result.data?.priceList) {
          this.priceListList = result.data.priceList;
        } else if (result.response) {
          this.errorMessage = result.response;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loaded = true;
      }
    },
    async fetchPriceListProposalWarehouseList() {
      const result = await priceListProposalService.getWarehouseList();
      if (
        result &&
        result.data &&
        result.data.warehouses &&
        result.data.warehouses.length > 0
      ) {
        this.warehouseList = result.data.warehouses;
      } else {
        this.warehouseList = [];
      }
    },
    async fetchSuggestedPriceListProposalWarehouse(priceListId) {
      const result = await priceListProposalService.getSuggestedWarehouseList(
        priceListId ? priceListId : this.priceListIdModel
      );
      if (
        result &&
        result.data &&
        result.data.warehouses &&
        result.data.warehouses.length > 0
      ) {
        this.warehouseListModel = result.data.warehouses[0].warehouseId;
      } else {
        this.warehouseListModel = undefined;
      }
    },
    async closePriceList() {
      console.log(this.priceList);
      if (this.$refs.form.validate()) {
        let previosFromDate = this.priceList.fromDate;
        let previuosToDate = this.priceList.toDate;
        this.priceList.fromDate = this.fromDatePickerModel;
        this.priceList.toDate = this.$dayjs(
          this.toDatePickerModel,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");

        const closeResult = await priceListProposalService.close(
          this.priceList
        );
        console.log("closeResult", closeResult);
        if (closeResult.response.status === 0) {
          this.$emit("submit", closeResult.data);
        } else {
          this.priceList.fromDate = previosFromDate;
          this.priceList.toDate = previuosToDate;
          //this.$emit("submit", false);
        }
      }
    },
    abort() {
      this.$emit("submit", false);
    },
    handleDateChange(date) {
      this.selectedDate = date;
      this.menutoDate = false;
    }
  },
  async mounted() {
    this.fromDatePickerModel = this.priceList.fromDate;
  }
};
</script>
