<template>
  <v-list-item class="product-price-item">
    <router-link
      :to="{
        name: 'Product',
        params: { slug: productPrice.product.slug }
      }"
    >
      <v-list-item-avatar width="60" height="60" rounded="0">
        <!-- <ProductPromo
        v-if="productPrice.product.warehousePromo"
        :warehousePromo="productPrice.product.warehousePromo"
      /> -->
        <img :src="productPrice.product.mediaURL" :alt="'Immagine prodotto'" />
      </v-list-item-avatar>
    </router-link>
    <v-list-item-content class="cart-item-content">
      <div class="descripion">
        <v-list-item-subtitle class="text-wrap">
          <span
            v-for="(category, index) in lookupCategoryPath(
              productPrice.product.categoryId
            )"
            :key="category.categoryId"
          >
            {{ category.name }}
            <span
              v-if="
                index + 1 <
                  lookupCategoryPath(productPrice.product.categoryId).length
              "
              >></span
            >
          </span>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-uppercase black--text text-wrap">
          {{ productPrice.product.name }}
        </v-list-item-subtitle>

        <v-list-item-subtitle class="black--text">
          <strong> {{ productPrice.product.description }} </strong>
        </v-list-item-subtitle>
        <v-list-item-title class="black--text font-weight-bold">
          {{ productPrice.product.shortDescr }}
        </v-list-item-title>
        <v-list-item-subtitle class="black--text"
          >Cod. <strong>{{ productCode }}</strong></v-list-item-subtitle
        >
        <v-list-item-subtitle
          class="black--text"
          v-if="product.productInfos && product.productInfos.QTY_LOCKED"
        >
          {{ $t("priceList.priceListItem.quantityMult")
          }}<b>{{ product.productInfos.QTY_MULT }} pz</b>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="black--text"
          v-if="
            product.productInfos && product.productInfos.TIPOLOGIA != 'Pezzo'
          "
        >
          {{ $t("priceList.priceListItem.quantityMult")
          }}<b>{{ quantityPerUnit }}</b>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="black--text"
          v-if="productPrice.product.logisticPackagingItems > 0"
        >
          Imballo: {{ productPrice.product.logisticPackagingItems }} pz
        </v-list-item-subtitle>
      </div>
      <!-- <ProductPrice
        :product="item.product"
        :showStandard="false"
      ></ProductPrice> -->
    </v-list-item-content>
    <v-list-item-action class="handle-quantity flex-row align-center">
      <v-list-item-action-text class="product-price-price">
        <template v-if="proposalStatus == -1">
          <!-- <div class="base-price product-price">
            {{ $n(productPrice.product.priceDisplay, "currency") }}
          </div>
          |&nbsp;&nbsp; -->
          <template v-if="productPrice.referenceBaseProductPriceValue > 0">
            <div class="base-price reference-base-price">
              <div class="sticker base-price-sticker">B</div>
              <strong>{{
                $n(productPrice.referenceBaseProductPriceValue, "currency")
              }}</strong>
            </div>
            <!-- <span>({{ discountPercent }}%)</span> -->
            <v-icon small>mdi-arrow-right-bold</v-icon>
          </template>
        </template>
        <!-- :label="$t('priceList.priceListItem.newPrice')" -->
        <!-- @focus="$event.target.select()" -->
        <v-text-field
          v-model="newProductPricePrice"
          :disabled="proposalStatus != -1"
          :readonly="!productPrice.priceListValidationLevel"
          outlined
          @click.prevent.stop=""
          :class="
            productPrice.priceListValidationLevel
              ? `new-price-field`
              : `new-price-field error-price-field`
          "
          hide-details
          @blur="updateProductPrice"
        >
          <template v-slot:append>
            <!-- <span class="new-price-field-currency">€</span> -->
            <!--##### check sign #######-->
            <v-icon
              v-if="
                productPrice.priceListValidationLevel && proposalStatus == -1
              "
              :class="
                `validation-level validation-level-${productPrice.priceListValidationLevel.priceListValidationLevelId}`
              "
              >mdi-check-decagram</v-icon
            >
          </template>
          <template v-slot:prepend-inner>
            <div class="sticker new-price-sticker">P</div>
            <span class="new-price-field-currency">€</span>
          </template>
        </v-text-field>
        <!-- {{ $n(productPrice.price, "currency") }} -->
      </v-list-item-action-text>
      <div class="actions" v-if="!priceListProposalId">
        <template v-if="productPrice.product.available > 0">
          <div
            v-if="!showQntyInput"
            class="qty-wrap rounded-md"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <a
              role="button"
              class="minus"
              @click.stop.prevent="minus"
              @mousedown.stop
            >
              <v-icon>mdi-minus</v-icon>
            </a>
            <div
              class="val-cnt"
              @click.stop.prevent=""
              @dblclick="toggleShowInput"
              v-long-press="300"
              @long-press-start="toggleShowInput"
              @mousedown.stop
            >
              <span class="val">{{ quantity }} {{ unit }}</span>
              <span class="small">{{ quantityPerUnit }}</span>
            </div>
            <a
              role="button"
              class="plus"
              @click.stop.prevent="plus"
              @mousedown.stop
            >
              <v-icon>mdi-plus</v-icon>
            </a>
          </div>
          <div
            v-else
            class="qnty-manual-input rounded-md"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <a
              role="button"
              class="close"
              @click.stop.prevent="toggleShowInput(false)"
              @mousedown.stop
            >
              <v-icon>mdi-close</v-icon>
            </a>
            <v-tooltip
              bottom
              :open-on-hover="false"
              :open-on-click="true"
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="qntyInput"
                  ref="manualInput"
                  class="manual-input"
                  hide-details
                  autofocus
                  outlined
                  dense
                  v-mask="'###'"
                  type="number"
                  @click.stop.prevent=""
                  @keyup.enter="toggleShowInput(true)"
                  @keyup.esc="toggleShowInput(false)"
                  @mousedown.stop
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span
                class="qntity-tooltip"
                v-if="
                  productPrice.product.productInfos.QTY_LOCKED != 'true' ||
                    (productPrice.product.productInfos.QTY_LOCKED == 'true' &&
                      !modulus(qntyInput))
                "
                v-html="
                  $t('product.quantity.confirmQntity', {
                    qntyInput: this.qntyInput
                  })
                "
                @click="clicked"
              >
              </span>
              <span
                class="qntity-tooltip"
                v-if="
                  productPrice.product.productInfos.QTY_LOCKED == 'true' &&
                    modulus(qntyInput) &&
                    parseInt(qntyInput) <=
                      parseInt(productPrice.product.productInfos.QTY_MULT)
                "
                v-html="
                  $t('product.quantity.editQntityMin', {
                    lowerInput: this.lowerInput
                  })
                "
                @click="clicked"
              >
              </span>

              <span
                class="qntity-tooltip"
                v-if="
                  productPrice.product.productInfos.QTY_LOCKED == 'true' &&
                    modulus(qntyInput) &&
                    parseInt(qntyInput) >
                      parseInt(productPrice.product.productInfos.QTY_MULT)
                "
                v-html="
                  $t('product.quantity.editQntityMinMax', {
                    lowerInput: this.lowerInput,
                    higherInput: this.higherInput
                  })
                "
                @click="clicked"
              >
              </span>
            </v-tooltip>
            <a
              role="button"
              class="check"
              @click.stop.prevent="toggleShowInput(true)"
              @mousedown.stop
            >
              <v-icon>mdi-check</v-icon>
            </a>
          </div>
          <v-btn
            class="primary add"
            elevation="0"
            @click.stop.prevent="addToCart(quantity)"
          >
            <v-icon>$cart</v-icon></v-btn
          >
          <v-btn
            v-if="enableAcqBox && productPrice.product.logisticPalletItems"
            :aria-label="
              `Aggiungi un cartone da ${productPrice.product.logisticPalletItems} al carrello`
            "
            color="primary"
            outlined
            class="btn-add-box ml-1"
            small
            @click.stop.prevent="
              addToCart(productPrice.product.logisticPalletItems, undefined)
            "
          >
            <div class="text-uppercase">
              <v-icon color="primary">$cart</v-icon>
              <div>{{ $t("priceList.priceListItem.largePackage") }}</div>
              <div class="small">
                = {{ productPrice.product.logisticPalletItems }}
                {{ $t("priceList.priceListItem.pz") }}
              </div>
            </div>
          </v-btn>
        </template>
      </div>
      <!-- <v-chip> -->

      <!-- </v-chip> -->
      <v-btn
        v-if="proposalStatus == -1"
        @click="updateProductPrice"
        icon
        class="update-product-price-btn"
        large
      >
        <v-icon default>
          mdi-content-save-edit
        </v-icon>
      </v-btn>
      <v-btn
        v-if="priceListProposalId != undefined && proposalStatus == -1"
        large
        icon
        @click.stop.prevent="deleteProductPrice"
        @mousedown.stop
        class="delete-product-price-btn"
      >
        <v-icon>$delete</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<style lang="scss">
.product-price-item {
  .update-product-price-btn {
    .v-icon {
      font-size: 26px;
    }
    // border-left: 1px solid ;
  }
  .base-price {
    margin-right: 6px;
    display: flex;
    align-items: center;
  }
  .sticker {
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    &.base-price-sticker {
      background-color: var(--v-grey-base);
      margin-right: 4px;
    }
    &.new-price-sticker {
      background-color: var(--v-primary-base);
      margin-top: -1px;
      margin-right: 4px;
    }
  }
  .new-price-field {
    width: 150px;
    text-align: center;
    fieldset {
      border-color: var(--v-primary-base);
    }
    &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      .v-input__control
      .v-input__slot {
      padding-right: 0px;
      padding-left: 8px;
    }
    .v-text-field__slot {
      input {
        text-align: left;
        font-weight: bold;
        color: var(--v-primary-base);
      }
    }
    // .v-input__append-inner {
    //   background-color: var(--v-primary-lighten2);
    // }
    .new-price-field-currency {
      margin-top: 3px;
      color: var(--v-primary-base);
    }
    .v-input__append-inner {
      // border-left: 1px solid var(--v-grey-lighten2);
      margin-top: 0px;
      display: flex;
      align-items: center;
      height: 56px;
    }
  }
  .error-price-field {
    fieldset {
      border: none !important;
    }
  }
  .v-list-item__action-text.product-price-price {
    display: flex;
    // flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 0px 8px;
  }
  .delete-product-price-btn {
    margin-left: 4px;
    .v-icon:before {
      font-size: 24px;
    }
  }
  .validation-level {
    font-size: 28px;
  }
  .validation-level-1 {
    color: var(--v-success-base);
  }
  .validation-level-2 {
    color: var(--v-warning-base);
  }
  .validation-level-3 {
    color: var(--v-error-base);
  }
}
.price-list-detail-view {
  // .product-price-item .new-price-field .v-text-field__slot input {
  //   text-align: center;
  // }
}
</style>
<script>
import productMixin from "~/mixins/product";

import { formatProductCode } from "@/service/grosmarketUtils";

import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

export default {
  name: "ProductPriceItem",
  mixins: [productMixin],
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  props: {
    productPrice: { type: Object, required: true },
    priceListProposalId: { type: Number, required: false },
    proposalStatus: { type: Number, required: false }
  },
  data() {
    return {
      newProductPricePrice: 0,
      canUpdateProductPrice: true
    };
  },
  computed: {
    ...mapGetters({
      lookupCategoryPath: "category/lookupCategoryPath"
    }),
    product() {
      return this.productPrice.product;
    },
    productCode() {
      return formatProductCode(this.product);
    },
    discountPercent() {
      return (
        ((parseFloat(this.productPrice.price) -
          this.productPrice.referenceBasePrice) /
          this.productPrice.referenceBasePrice) *
        100
      ).toFixed(1);
    },
    enableAcqBox() {
      return (
        this.product.productInfos?.ENABLE_ACQ_BOX &&
        this.product.productInfos?.ENABLE_ACQ_BOX !== "no"
      );
    }
  },
  methods: {
    async deleteProductPrice() {
      const agreedConfirm = await this.$dialog.confirm({
        text: global.vm.$t("priceList.priceListItem.deleteConfirmDialog", {
          productName: this.product.name
        })
      });
      if (agreedConfirm) {
        this.$emit("deleteProductPrice", {
          priceId: this.productPrice.priceId,
          priceListProposalId: this.priceListProposalId
        });
        // const result = priceListProposalService.deleteProductPrice(
        //   this.productPrice.priceId,
        //   this.priceListProposalId
        // );
        // console.log("deleteProductPrice", result);
      }
    },
    updateProductPrice() {
      if (this.canUpdateProductPrice) {
        this.canUpdateProductPrice = false;
        const newProductPricePriceFormatted = parseFloat(
          this.newProductPricePrice.replace(",", ".")
        );
        if (this.productPrice.price != newProductPricePriceFormatted) {
          this.$emit("updateProductPrice", {
            price: newProductPricePriceFormatted,
            priceId: this.productPrice.priceId
          });
        }
        setTimeout(() => {
          this.canUpdateProductPrice = true;
        }, 200);
      }
    }
  },
  mounted() {
    this.newProductPricePrice = this.productPrice.price
      .toFixed(2)
      .toString()
      .replace(".", ",");
  }
};
</script>
